import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import ErrorPage from '../routes/ErrorPage';

function AdminRoute() {
  const { currentUser } = useContext(AuthContext);
  return !currentUser ? <ErrorPage /> : <Outlet />;
}

export default AdminRoute;
