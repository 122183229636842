import '../Styles/forms.css';
import PopupMsg from './PopupMsg';
import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

function ContactForm(props) {
  const [popup, setPopup] = useState(0);
  const [message, setMessage] = useState('');
  const [classIcon, setClassIcon] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'contact_service',
        'contact_form',
        e.target,
        process.env.REACT_APP_EMAILJS_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          setMessage('¡Pronto estaremos en contacto!');
          setClassIcon('fa fa-check');
          setPopup(1);
        },
        (error) => {
          console.log(error.text);
          setMessage('Ha ocurrido un error.');
          setClassIcon('fa fa-times');
          setPopup(1);
        }
      );
  };

  function handlePopup() {
    setPopup(popup > 0 ? 0 : 1);
  }

  return (
    <div className='container-form'>
      <h1>Somos ONG PROGRESA y brindamos capital de trabajo</h1>
      <span>Si desea ser evaluado, proporciona los siguientes datos:</span>
      <form onSubmit={sendEmail}>
        <input
          type='text'
          name='fullname'
          placeholder='Nombres y Apellidos*'
          required
        />

        <div className='input-group'>
          <select name='tipo'>
            <option value='DNI'>DNI</option>
            <option value='Pasaporte'>Pasaporte</option>
            <option value='CEX'>CEX</option>
          </select>

          <input
            type='number'
            name='docnum'
            placeholder='Numero de documento*'
            required
          />
        </div>
        <input type='text' name='rubro' placeholder='Rubro de su negocio*' />
        <select name='house'>
          <option value='propia'>Casa propia</option>
          <option value='alquilada'>Alquilada</option>
          <option value='otra'>Otra</option>
        </select>
        <input
          type='text'
          name='direccion'
          placeholder='Dirección de su negocio*'
        />
        <input type='number' name='phone' placeholder='Teléfono*' required />

        <label className='checklbl'>
          <input type='checkbox' required /> Deseo que PROGRESA y sus empresas
          me mantengan informado de ofertas y promociones.
        </label>

        <button type='submit'>Enviar</button>
      </form>

      <PopupMsg
        state={popup}
        hidePopup={handlePopup}
        iconClass={classIcon}
        title='Información'
        message={message}
      />
    </div>
  );
}

export default ContactForm;
