import c1 from '../assets/C1.jpg';
import c2 from '../assets/C2.jpg';
import c3 from '../assets/C3.jpg';
import c4 from '../assets/C4.jpg';
import p1 from '../assets/p1.png';
import p2 from '../assets/p2.png';
import p3 from '../assets/CAPACITACION.png';
import p4 from '../assets/Convocatoria.png';
import abaco from '../assets/Abaco.png';
import acnur from '../assets/ACNUR.png';
import cofide from '../assets/Cofide.png';
import copeme from '../assets/Copeme.png';
import encuentros from '../assets/Encuentros.png';
import fondesurco from '../assets/Fondesurco.png';
import santamaria from '../assets/Santamaria.png';
import alemana from '../assets/sparkassenstiftung.png';
import ddl1 from '../assets/BCP.svg';
import ddl2 from '../assets/Interbank.svg';
import ddl3 from '../assets/logo-yape.svg';
import ddl4 from '../assets/plin.svg';
import ddl5 from '../assets/mibanco.svg';

export const NavItems = [
  {
    title: 'Quiénes Somos',
    url: '/nosotros',
    class: 'nav-links',
    icon: 'fa fa-users',
    hierarchy: 0,
    submenu: false,
  },
  {
    title: 'Noticias',
    url: '/noticias',
    class: 'nav-links',
    icon: 'fa fa-newspaper-o',
    hierarchy: 0,
    submenu: false,
  },
  {
    title: 'Productos',
    url: '/productos',
    class: 'nav-links',
    icon: 'fa fa-briefcase',
    hierarchy: 0,
    submenu: false,
  },
  {
    title: 'Contacto',
    url: '/contacto',
    class: 'nav-links',
    icon: 'fa fa-address-book-o',
    hierarchy: 0,
    submenu: true,
  },
  {
    title: 'Canales de Pago',
    url: '/canales',
    class: 'nav-links',
    icon: 'fa fa-credit-card-alt',
    hierarchy: 0,
    submenu: false,
  },
  {
    title: 'Trabaja con Nosotros',
    url: '/contrataciones',
    class: 'nav-sublinks',
    icon: '',
    hierarchy: 1,
    submenu: false,
  },
  {
    title: 'Libro de Reclamaciones',
    url: '/reclamos',
    class: 'nav-sublinks',
    icon: '',
    hierarchy: 1,
    submenu: false,
  },
];

export const CarouselData = [
  {
    title: '¡SOLICITA TU CRÉDITO YA!',
    description: 'Tenemos la mejor opción de financiamiento para tu negocio',
    info1: '+51 941 479 449',
    info2:
      'Calle Los Sauces Mz. H2, Lt. 54, Urb. San Antonio de Carapongo, Lurigancho- Chosica.',
    icon1: 'fa fa-whatsapp',
    icon2: 'fa fa-map-marker',
    buttonText: '¡ADQUIERELO YÁ!',
    buttonLink: '/contacto',
    image: p1,
    bg: c2,
    class: 'item-container',
    titleColor: '#00217A',
    descripColor: '#219ebc',
  },
  {
    title: 'PROGRESEMOS JUNTOS',
    description:
      'Peruanos y Venezolanos a través de nuestros créditos grupales solidarios.',
    info1: '+51 941 479 449',
    info2:
      'Calle Los Sauces Mz. H2, Lt. 54, Urb. San Antonio de Carapongo, Lurigancho- Chosica.',
    icon1: 'fa fa-whatsapp',
    icon2: 'fa fa-map-marker',
    buttonText: '',
    buttonLink: '',
    image: p2,
    bg: c1,
    class: 'item-container-reverse',
    titleColor: '#00217A',
    descripColor: '#219ebc',
  },
  {
    title: 'CAPACITACIÓN Y ASITENCIA TÉCNICA',
    description: 'Complementan nuestros créditos individuales y grupales.',
    info1:
      'Calle Los Sauces Mz. H2, Lt. 54, Urb. San Antonio de Carapongo, Lurigancho- Chosica.',
    info2: '',
    icon1: 'fa fa-map-marker',
    icon2: '',
    buttonText: '',
    buttonLink: '',
    image: p3,
    bg: c4,
    class: 'item-container',
    titleColor: '#F7F7F7',
    descripColor: '#219ebc',
  },
  {
    title: 'UNETE A NUESTRO EQUIPO',
    description: 'Conviértete en un agente de cambio en nuestra sociedad.',
    info1:
      'Calle Los Sauces Mz. H2, Lt. 54, Urb. San Antonio de Carapongo, Lurigancho- Chosica.',
    info2: '',
    icon1: 'fa fa-map-marker',
    icon2: '',
    buttonText: '¡APLICA AQUÍ!',
    buttonLink: '/contrataciones',
    image: p4,
    bg: c3,
    class: 'item-container-reverse',
    titleColor: '#00217A',
    descripColor: '#219ebc',
  },
];

export const AddressItems = [
  {
    title: 'Principal',
    content: {
      address: 'Calle Los Sauces Mz G2 Lt 27 Urb. San Antonio de Carapongo.',
      district: 'Lurigancho - Chosica',
      phone: '+51 960778238',
      email: 'aprincipal.progresa@gmail.com',
      schedule: 'Lunes a Viernes (8 am - 5 pm)',
      map: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3902.5692724239784!2d-76.8738889!3d-12.004277799999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDAwJzE1LjQiUyA3NsKwNTInMjYuMCJX!5e0!3m2!1ses!2spt!4v1716151663053!5m2!1ses!2spt',
    },
  },
  {
    title: 'Carapongo ',
    content: {
      address:
        'Calle Los Sauces Mz. H2 Lote 54, Urb. San Antonio de Carapongo.',
      district: 'Lurigancho - Chosica',
      phone: '+51 982812797',
      email: 'carapongo.progresa@gmail.com',
      schedule: 'Lunes a Viernes (8 am - 5 pm)',
      map: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3902.5842634278156!2d-76.87667772493961!3d-12.00324268823016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDAwJzExLjciUyA3NsKwNTInMjYuOCJX!5e0!3m2!1ses!2spt!4v1716151584328!5m2!1ses!2spt',
    },
  },
  {
    title: 'Huaycan',
    content: {
      address:
        'Av. José Carlos Mariátegui Mz. B Lote 7 Res. Las Praderas de Pariachi.',
      district: 'Ate',
      phone: '+51 982812798',
      email: 'nana.progresa@gmail.com',
      schedule: 'Lunes a Viernes (8 am - 5 pm)',
      map: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3902.607487771554!2d-76.83769439999999!3d-12.0016389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDAwJzA1LjkiUyA3NsKwNTAnMTUuNyJX!5e0!3m2!1ses!2spt!4v1716151499546!5m2!1ses!2spt',
    },
  },
  {
    title: 'Huachipa',
    content: {
      address:
        'Avenida Las Torres Lote 18 B - 1er Piso, Asoc. Dignidad Nacional.',
      district: 'Lurigancho - Chosica',
      phone: '+51 960819276',
      email: 'cajamarquilla.progresa@gmail.com',
      schedule: 'Lunes a Viernes (8 am - 5 pm)',
      map: 'https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3902.445639115649!2d-76.9047222249394!3d-12.01281118822133!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTLCsDAwJzQ2LjEiUyA3NsKwNTQnMDcuNyJX!5e0!3m2!1ses!2spt!4v1716151314819!5m2!1ses!2spt',
    },
  },
];

export const PartnersData = [
  {
    name: 'Abaco',
    image: abaco,
    url: 'https://www.abaco.com.pe/',
  },
  {
    name: 'ACNUR',
    image: acnur,
    url: 'https://www.acnur.org/',
  },
  {
    name: 'Fondesurco',
    image: fondesurco,
    url: 'https://www.fondesurco.pe/',
  },
  {
    name: 'Sparkassenstiftung',
    image: alemana,
    url: 'https://sparkassenstiftung-latinoamerica.org/',
  },
  {
    name: 'Copeme',
    image: copeme,
    url: 'https://www.copeme.org.pe/',
  },
  {
    name: 'Cofide',
    image: cofide,
    url: 'https://www.cofide.com.pe/',
  },
  {
    name: 'Cooperativa Santa Maria',
    image: santamaria,
    url: 'https://www.coopsantamaria.com.pe/',
  },
  {
    name: 'Encuentros',
    image: encuentros,
    url: 'https://www.encuentros-sjs.org/',
  },
];

export const DDListData = [
  {
    image: ddl1,
    color: '#004388',
    description: [
      {
        title: 'Cuenta BCP',
        info1: 'Titular: ASOCIACIÓN PARA LA PROMOCIÓN',
        info2: 'Número: 191-03775490-0-24',
        info3: 'CCI: 00219110377549002450',
      },
    ],
  },
  {
    image: ddl2,
    color: '#05be50',
    description: [
      {
        title: 'Cuenta Interbank',
        info1: 'Titular: ASOCIACIÓN PARA LA PROMOCIÓN',
        info2: 'Número: 200-3001493815',
        info3: 'CCI: 003-200-003001493815-30',
      },
    ],
  },
  {
    image: ddl3,
    color: '#6f0374',
    description: [
      {
        title: 'Pagos Yape',
        info1: 'Titular: MAXIMO DUEÑES',
        info2: 'Número: 941 479 449',
        info3: 'Nuestros agentes le contactaran una vez sea confirmado.',
      },
    ],
  },
  {
    image: ddl4,
    color:
      'linear-gradient(15deg, rgba(39,135,246,1) 0%, rgba(0,225,206,1) 100%)',
    description: [
      {
        title: 'Pagos Plin',
        info1: 'Titular: MAXIMO DUEÑES',
        info2: 'Número: 941 479 449',
        info3: 'Nuestros agentes le contactaran una vez sea confirmado.',
      },
    ],
  },
  {
    image: ddl5,
    color: '#00a650',
    description: [
      {
        title: 'Cuenta Mibanco',
        info1: 'Titular: ASOCIACIÓN PARA LA PROMOCIÓN',
        info2: 'Número: 60-22298346',
        info3: 'CCI: 0494-120060222983-4606',
      },
    ],
  },
];
