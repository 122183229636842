import React from 'react';
import { Link } from 'react-router-dom';

function HiringFormat({ id, title, date, resume, image }) {
  function formatDate(data) {
    let d = new Date(data);

    const date = [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear(),
    ].join('-');

    return date;
  }

  function handleForm(e) {}
  return (
    <div className='hiringformat-container'>
      <div className='item-img'>
        <img src={`${process.env.REACT_APP_DOMAIN}/${image}`} alt='' />
      </div>

      <div className='item-text'>
        <div className='top-content'>
          <h2 className='title'>{title}</h2>
          <span className='date'>
            <i className='fa fa-calendar' aria-hidden='true'></i>
            Publicado el {formatDate(date)}
          </span>
        </div>

        <p className='resumen'>{resume}</p>

        <div className='bottom-content'>
          <button className='btn' onClick={(e) => handleForm(e)}>
            Postular
          </button>
          <Link className='h-btn' to={`/contrataciones/${id}`}>
            Información detallada
            <span>
              <i className='fa fa-chevron-right' aria-hidden='true'></i>
              <i className='fa fa-chevron-right' aria-hidden='true'></i>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HiringFormat;
