import React from 'react';
import { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import PopupMessage from './PopupMsg';
import '../Styles/forms.css';
import moment from 'moment';

const USER_REGEX = /^[a-zA-Z][a-zA-Z0-9-_]{3,23}$/;
const PASS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;

function RegisterForm() {
  const userRef = useRef();
  const passRef = useRef();
  const passconfirmRef = useRef();

  const [user, setUser] = useState('');
  const [validName, setValidName] = useState(false);
  const [userFocus, setUserFocus] = useState(false);

  const [pass, setPass] = useState('');
  const [validPass, setValidPass] = useState(false);
  const [PassFocus, setPassFocus] = useState(false);

  const [matcPass, setMatcPass] = useState('');
  const [validMatch, setValidMatch] = useState(false);
  const [matchFocus, setMatchFocus] = useState(false);

  const [popup, setPopup] = useState(0);
  const [classIcon, setClassIcon] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const { signup } = useContext(AuthContext);

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    const result = USER_REGEX.test(user);
    setValidName(result);
  }, [user]);

  useEffect(() => {
    const result = PASS_REGEX.test(pass);
    setValidPass(result);
    const match = pass === matcPass;
    setValidMatch(match);
  }, [pass, matcPass]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validName) {
      setClassIcon('fa fa-times');
      setPopup(1);
      return setMessage('Usuario no permitido.');
    }

    if (passRef.current.value !== passconfirmRef.current.value) {
      setClassIcon('fa fa-times');
      setPopup(1);
      return setMessage('La contraseña no coincide.');
    }

    let actualDate = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
    signup(userRef.current.value, passRef.current.value, actualDate).then(
      (response) => {
        if (response.status === 200) {
          setMessage(response.data);
          setClassIcon('fa fa-check');
          setPopup(1);
          setUser('');
          setPass('');
          setMatcPass('');
        } else {
          setMessage(response.data);
          setClassIcon('fa fa-times');
          setPopup(1);
        }
      }
    );

    setLoading(false);
  };

  function handlePopup() {
    setPopup(popup > 0 ? 0 : 1);
  }

  return (
    <div className='register-form'>
      <h1>Registro</h1>
      <form onSubmit={handleSubmit}>
        <input
          type='text'
          id='username'
          ref={userRef}
          value={user}
          autoComplete='off'
          onChange={(e) => setUser(e.target.value)}
          required
          placeholder='Nombre de usuario'
          aria-invalid={validName ? 'false' : 'true'}
          aria-describedby='uidnote'
          onFocus={() => setUserFocus(true)}
          onBlur={() => setUserFocus(false)}
        />
        <p
          id='uidnote'
          className={
            userFocus && user && !validName
              ? 'instructions'
              : 'instructions offscreen'
          }
        >
          <i className='fa fa-info-circle' aria-hidden='true'></i>
          4 a 24 caracteres.
          <br />
          Debe comenzar con una letra.
          <br />
          Letras, numeros, guion y guion bajo permitido.
        </p>

        <input
          type='password'
          id='password'
          ref={passRef}
          value={pass}
          onChange={(e) => setPass(e.target.value)}
          required
          placeholder='Contraseña'
          aria-invalid={validPass ? 'false' : 'true'}
          aria-describedby='pwdnote'
          onFocus={() => setPassFocus(true)}
          onBlur={() => setPassFocus(false)}
        />
        <p
          id='pwdnote'
          className={
            PassFocus && !validPass ? 'instructions' : 'instructions offscreen'
          }
        >
          <i className='fa fa-info-circle' aria-hidden='true'></i>
          8 a 24 caracteres.
          <br />
          Debe contener al menos una letra mayuscula, una minuscula, un numero y
          un caracter especial.
          <br />
          Caracteres permitidos:
          <span aria-label='simbolo exclamacion'>!</span>
          <span aria-label='arroba'>@</span>
          <span aria-label='numeral'>#</span>
          <span aria-label='simbolo dolar'>$</span>
          <span aria-label='porcentaje'>%</span>
        </p>

        <input
          type='password'
          id='confirm_pass'
          ref={passconfirmRef}
          value={matcPass}
          onChange={(e) => setMatcPass(e.target.value)}
          required
          placeholder='Confirmar contraseña'
          aria-invalid={validMatch ? 'false' : 'true'}
          aria-describedby='confirmnote'
          onFocus={() => setMatchFocus(true)}
          onBlur={() => setMatchFocus(false)}
        />
        <p
          id='confirmnote'
          className={
            matchFocus && !validMatch
              ? 'instructions'
              : 'instructions offscreen'
          }
        >
          <i className='fa fa-info-circle' aria-hidden='true'></i>
          Debe conincidir con la contraseña ingresada.
        </p>

        <button disabled={loading}>Registrar</button>
      </form>

      <div className='question'>
        <p>
          ¿Ya estas registrado?
          <Link to={'/iniciarsesion'}>Iniciar sesión</Link>
        </p>
      </div>

      <PopupMessage
        state={popup}
        hidePopup={handlePopup}
        iconClass={classIcon}
        title='Información'
        message={message}
      />
    </div>
  );
}

export default RegisterForm;
