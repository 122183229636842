import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CarouselItem from '../components/CarouselItem';
import { CarouselData } from '../components/dataItems';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: '95%' }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, left: '3%', zIndex: '2' }}
      onClick={onClick}
    />
  );
}

function DisableArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'none' }}
      onClick={onClick}
    />
  );
}

function Carousel({ children }) {
  const btnColor = '#E80A24';
  const [arrows, setArrows] = useState(true);
  const settings = {
    dots: true,
    swipe: true,
    infinite: false,
    speed: 800,
    initialSlide: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 6000,
    autoplay: false,
    centerMode: false,
    fade: false,
    pauseOnDotsHover: false,
    pauseOnFocus: false,
    pauseOnHover: true,
    rtl: false, //movimiento inverso
    vertical: false, //movimiento vertical
    adaptiveHeight: false,
    nextArrow: arrows ? <SampleNextArrow /> : <DisableArrow />,
    prevArrow: arrows ? <SamplePrevArrow /> : <DisableArrow />,
  };

  function windowsWidth() {
    window.innerWidth <= 650 ? setArrows(false) : setArrows(true);
  }

  useEffect(() => {
    windowsWidth();
  }, []);

  return (
    <Slider className='carousel' {...settings}>
      {CarouselData.map((item, index) => {
        return (
          <CarouselItem
            key={index}
            class={item.class}
            bgImage={item.bg}
            title={item.title}
            description={item.description}
            btnText={item.buttonText}
            btnLink={item.buttonLink}
            btnColor={btnColor}
            ProductImage={item.image}
            info1={item.info1}
            info2={item.info2}
            icon1={item.icon1}
            icon2={item.icon2}
            colorTitle={item.titleColor}
            colorDescription={item.descripColor}
          />
        );
      })}
    </Slider>
  );
}

export default Carousel;
