import React from 'react';
import Hero from '../components/Hero';
import HeroImg from '../assets/Nosotros.jpg';
import Blog from '../components/Blog';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Nosotros() {
  return (
    <div>
      <Navbar />
      <Hero
        clase='hero'
        img={HeroImg}
        title='Nosotros'
        textstyle='Hero-text'
        btnstyle='hide'
      />
      <Blog />
      <Footer />
    </div>
  );
}

export default Nosotros;
