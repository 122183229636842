import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import Hero from '../components/Hero';
import HeroImg from '../assets/hero2.jpg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../Styles/forms.css';

function Perfil() {
  const [visit, setVisit] = useState('');
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const fetcData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/visitas`
        );
        setVisit(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetcData();
  }, []);

  return (
    <div>
      <Navbar />
      <Hero
        clase='hero-mid'
        img={HeroImg}
        title='Perfil'
        textstyle='Hero-text-mid'
        btnstyle='hide'
      />

      <div className='profile-container'>
        <div className='content'>
          <h2>Datos del sistema</h2>
          <div className='info-group'>
            <label>Nombre de Usuario</label>
            <span>{currentUser.username}</span>
          </div>
          <div className='info-group'>
            <label>Numero de visitas</label>
            <span>{visit.length > 0 && visit[1].cant}</span>
          </div>
          <div className='info-group'>
            <label>Numero de vistas</label>
            <span>{visit.length > 0 && visit[0].cant}</span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Perfil;
