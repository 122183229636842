import React from 'react';
import { Link } from 'react-router-dom';

function NewsFormat({ id, title, date, resume, image, index }) {
  function formatDate(data) {
    let d = new Date(data);

    const date = [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear(),
    ].join('-');

    return date;
  }

  return (
    <div
      className={
        index === 0
          ? 'newsformat-container first'
          : index % 2 === 0
          ? 'newsformat-container'
          : 'newsformat-container inverse'
      }
    >
      <div className='item-img'>
        <img src={`${process.env.REACT_APP_DOMAIN}/${image}`} alt='' />
      </div>

      <div className='item-text'>
        {index === 0 ? <span className='tag'>¡ULTIMA NOTICIA!</span> : ''}
        <Link className='title' to={`/noticias/${id}`}>
          {title}
        </Link>

        <p className='resumen'>{resume}</p>

        <div className='bottom-content'>
          <span className='date'>
            <i className='fa fa-calendar' aria-hidden='true'></i>
            {formatDate(date)}
          </span>

          <Link className='news-btn' to={`/noticias/${id}`}>
            Ver más
          </Link>
        </div>
      </div>
    </div>
  );
}

export default NewsFormat;
