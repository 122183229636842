import '../Styles/footer.css';
import ScrollToTop from 'react-scroll-to-top';
import Logo from '../assets/logo50.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  const waText =
    'https://wa.me/51941479449?text="Buenas, deseo hacer una consulta."';
  return (
    <div className='footer'>
      <div className='top'>
        <Link className='FooterLogo' to={'/'}>
          <img src={Logo} alt='Progresa' />
        </Link>

        <div>
          <a href='https://www.facebook.com/progresa.org/?locale=es_LA'>
            <i className='fa fa-facebook'></i>
          </a>
          <a href='/'>
            <i className='fa fa-instagram'></i>
          </a>
          <a href='/'>
            <i className='fa fa-youtube-play'></i>
          </a>
          <a href='/'>
            <i className='fa fa-linkedin'></i>
          </a>
        </div>
      </div>

      <div className='bottom'>
        <div>
          <h4>Conoce más</h4>
          <Link to={'/nosotros'}>Quiénes somos</Link>
          <Link to={'/productos'}>Productos</Link>
          <Link to={'/noticias'}>Noticias</Link>
        </div>

        <div>
          <h4>Servicio al cliente</h4>
          <Link to={'/contacto'}>Contactos</Link>
          <Link to={'/canales'}>Canales de Pago</Link>
          <Link to={'/reclamos'}>Libro de reclamaciones</Link>
        </div>

        <div>
          <h4>Trabaja con nosotros</h4>
          <Link to={'/contrataciones'}>Uneté a PROGRESA</Link>
        </div>

        <div>
          <h4>Contáctanos</h4>
          <a href='mailto:pdolorier@mcprogresa.org'>
            <i className='fa fa-envelope-o'></i>pdolorier@mcprogresa.org
          </a>
          <a href={waText}>
            <i className='fa fa-whatsapp' aria-hidden='true'></i>+51 941 479 449
          </a>
          <a href='tel:51941479449'>
            <i className='fa fa-phone' aria-hidden='true'></i> +51 941 479 449
          </a>
          <a href='/'>
            <i className='fa fa-map-marker' aria-hidden='true'></i>Calle Los
            Sauces Mz G2 Lt 27 Urb. San Antonio de Carapongo
          </a>
        </div>
      </div>
      <ScrollToTop
        smooth
        className='scroll'
        svgPath='M11 8.414V18h2V8.414l4.293 4.293 1.414-1.414L12 4.586l-6.707 6.707 1.414 1.414z'
        viewBox='0 0 24 20'
        width='32'
        height='32'
        color='#ffffff'
      />
    </div>
  );
};

export default Footer;
