import React from 'react';
import '../Styles/address.css';

function Address(props) {
  return (
    <div className='address'>
      <div className='address-text'>
        <li>
          <p>
            <i className='fa fa-map-marker' aria-hidden='true'></i>
            {props.address}
          </p>
        </li>
        <li>
          <p>
            <i className='fa fa-location-arrow' aria-hidden='true'></i>
            {props.district}
          </p>
        </li>
        <li>
          <p>
            <i className='fa fa-phone' aria-hidden='true'></i>
            {props.phone}
          </p>
        </li>
        <li>
          <p>
            <i className='fa fa-envelope' aria-hidden='true'></i>
            {props.email}
          </p>
        </li>
        <li>
          <p>
            <i className='fa fa-clock-o' aria-hidden='true'></i>
            {props.schedule}
          </p>
        </li>
      </div>

      <div className='address-map'>{props.map}</div>
    </div>
  );
}

export default Address;
