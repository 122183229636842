import React from 'react';
import { useState, useContext, useRef, useEffect } from 'react';
import PopupMessage from './PopupMsg';
import { AuthContext } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

function LoginForm() {
  const userRef = useRef();
  const passRef = useRef();

  const [message, setMessage] = useState('');
  const [popup, setPopup] = useState(0);
  const [classIcon, setClassIcon] = useState('');
  const [loading, setLoading] = useState(false);

  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  function handleSubmit(e) {
    e.preventDefault();

    login(userRef.current.value, passRef.current.value).then((response) => {
      if (response.status === 200) {
        setMessage('');
        setLoading(true);
        navigate('/');
      } else {
        setMessage(response.data);
        setClassIcon('fa fa-times');
        setPopup(1);
      }
    });

    setLoading(false);
  }

  function handlePopup() {
    setPopup(popup > 0 ? 0 : 1);
  }

  return (
    <div className='register-form'>
      <h1>Inicio de sesion</h1>

      <form className='login' onSubmit={handleSubmit}>
        <input
          type='text'
          id='username'
          ref={userRef}
          autoComplete='off'
          required
          placeholder='Nombre de usuario'
        />
        <input
          type='password'
          id='password'
          ref={passRef}
          required
          placeholder='Contraseña'
        />
        <button disabled={loading}>Iniciar</button>

        <div className='question'>
          <Link to='/'>¿Olvidaste tu contraseña?</Link>
          <p>
            ¿Necesitas una cuenta? <Link to={'/registro'}>Regístrate aquí</Link>
          </p>
        </div>
      </form>

      <PopupMessage
        state={popup}
        hidePopup={handlePopup}
        iconClass={classIcon}
        title='Información'
        message={message}
      />
    </div>
  );
}

export default LoginForm;
