import '../Styles/hero.css';

function Hero(props) {
  return (
    <>
      <div className={props.clase}>
        <img alt='HeroImg' src={props.img} />
      </div>

      <div className={props.textstyle}>
        <div className='frame-1'>
          <h1>{props.title}</h1>
        </div>
        <div className='frame-2'>
          <h3>{props.text}</h3>
        </div>
        <div className={`${props.btnstyle} frame-3`}>
          <a href={props.url}>
            {props.btnText}
            <i className={`${props.btnstyle} fa fa-chevron-right`}></i>
          </a>
        </div>
      </div>
    </>
  );
}

export default Hero;
