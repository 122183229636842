import React from 'react';
import '../Styles/popupMsg.css';

function PopupMsg({ state, hidePopup, iconClass, title, message }) {
  return (
    <div className={state > 0 ? 'popup-container active' : 'popup-container'}>
      <div className='popup-content'>
        <i className={iconClass} aria-hidden='true'></i>

        <div className='message'>
          <span className='text1'>{title}</span>
          <span className='text2'>{message}</span>
        </div>
      </div>
      <i
        className='fa fa-times close'
        onClick={hidePopup}
        aria-hidden='true'
      ></i>

      <div className={state > 0 ? 'progress active' : 'progress'}></div>
    </div>
  );
}

export default PopupMsg;
