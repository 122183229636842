import React from 'react';
import ContactForm from '../components/ContactForm';
import Tabs from '../components/Tabs';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Contacto() {
  return (
    <>
      <div className='header-space'>
        <Navbar />
        <div className='Contact-container'>
          <div className='col1'>
            <ContactForm />
          </div>
          <div className='col2'>
            <Tabs />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Contacto;
