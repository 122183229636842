import React, { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CreateHiring from '../components/hiring/CreateHiring';
import HiringFormat from '../components/hiring/HiringFormat';

function Contrataciones() {
  const { currentUser, hirings } = useContext(AuthContext);

  return (
    <>
      <Navbar />
      <div className='header-space'>
        {currentUser ? <CreateHiring /> : ''}

        {hirings.length > 0 &&
          hirings.map((post, index) => (
            <HiringFormat key={post.id} {...post} />
          ))}
      </div>
      <Footer />
    </>
  );
}

export default Contrataciones;
