import React from 'react';
import Information from '../components/Information';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Reclamos() {
  return (
    <>
      <Navbar />
      <div className='header-space'>
        <Information />
      </div>

      <Footer />
    </>
  );
}

export default Reclamos;
