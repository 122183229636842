import React, { useState } from 'react';
import Address from './Address';
import '../Styles/tabs.css';
import { AddressItems } from './dataItems';

function Tabs() {
  const [ind, setInd] = useState(0);

  return (
    <div className='Tabs-container'>
      <span>ESTAMOS CERCA DE TI</span>
      <h3>Conoce nuestras sucursales</h3>
      <div className='tabs-box'>
        {AddressItems.map((item, index) => {
          return (
            <button
              className={index === ind ? 'active' : ''}
              key={index}
              onClick={() => setInd(index)}
            >
              {item.title}
            </button>
          );
        })}
      </div>
      {AddressItems.map((item, index) => {
        return (
          <div
            key={index}
            className={index === ind ? 'tabs-body active' : 'tabs-body'}
          >
            <Address
              address={item.content.address}
              district={item.content.district}
              phone={item.content.phone}
              email={item.content.email}
              schedule={item.content.schedule}
              map={
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                <iframe
                  src={item.content.map}
                  width='600'
                  height='450'
                  style={{ border: 0 }}
                  allowFullScreen=''
                  loading='lazy'
                  referrerPolicy='no-referrer-when-downgrade'
                ></iframe>
              }
            />
          </div>
        );
      })}
    </div>
  );
}

export default Tabs;
