import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import NotFound from '../NotFound';
import DOMPurify from 'dompurify';

function SingleNews() {
  const [postInfo, setPostInfo] = useState(null);
  const [isValid, setIsValid] = useState(true);
  const { id } = useParams();
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  function formatDate(data) {
    let d = new Date(data);

    const date = [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear(),
    ].join('-');

    return date;
  }

  async function handleDelete() {
    try {
      await axios.post(`${process.env.REACT_APP_DOMAIN}/api/erase/`, {
        path: postInfo.image,
      });

      await axios.delete(`${process.env.REACT_APP_DOMAIN}/api/noticias/${id}`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_CLIENT}`,
        },
      });
      navigate('/noticias');
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/noticias/${id}`
        );
        setPostInfo(res.data);
      } catch {
        setIsValid(false);
      }
    };
    fetchData();
  }, [id]);

  if (!isValid) return <NotFound />;
  if (!postInfo) return '';

  return (
    <>
      <Navbar />
      <div className='header-space'>
        <div className='singlenews-container'>
          <h1>{postInfo.title}</h1>

          <div className='middle-section'>
            <span>
              <i className='fa fa-calendar' aria-hidden='true'></i>{' '}
              {formatDate(postInfo.date)}
            </span>

            {currentUser ? (
              <div className='btn-panel'>
                <Link className='btn' to={`/noticias/editar/${postInfo.id}`}>
                  <i className='fa fa-pencil-square-o ' aria-hidden='true'></i>
                  Editar
                </Link>

                <button className='btn del' onClick={(e) => handleDelete(e)}>
                  <i className='fa fa-trash-o' aria-hidden='true'></i>
                  Eliminar
                </button>
              </div>
            ) : (
              <div className='social-media'>
                <a href='https://www.facebook.com/sharer/sharer.php?u=http://localhost:3000/'>
                  <i className='fa fa-facebook'></i>
                </a>
                <a href='https://api.whatsapp.com/send?text='>
                  <i className='fa fa-whatsapp'></i>
                </a>
                <a href='https://twitter.com/?status='>
                  <i className='fa fa-twitter'></i>
                </a>
                <a href='https://www.linkedin.com/shareArticle?url='>
                  <i className='fa fa-linkedin'></i>
                </a>
              </div>
            )}
          </div>

          <div className='item-image'>
            <img
              src={`${process.env.REACT_APP_DOMAIN}/${postInfo.image}`}
              alt=''
            />
          </div>
          <div
            className='item-content ql-editor'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(postInfo.content),
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SingleNews;
