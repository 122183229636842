import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { NavItems } from './dataItems';
import Logo from '../assets/Progresa-Logo.jpg';
import '../Styles/navbar.css';

function Navbar() {
  const [clicked, setClicked] = useState(false);
  const { logout, currentUser } = useContext(AuthContext);

  function handleClick() {
    setClicked(!clicked);
  }

  function handleSubmit(e) {
    e.preventDefault();

    try {
      logout();
    } catch {
      alert('Hubo un fallo al intentar cerrar la sesion.');
    }
  }

  useEffect(() => {
    const visitCounter = async (type) => {
      await axios.put(`${process.env.REACT_APP_DOMAIN}/api/visitas?${type}`);
    };
    sessionStorage.getItem('visit') == null
      ? visitCounter('type=visit')
      : visitCounter('type=view');

    sessionStorage.setItem('visit', 'x');
  }, []);

  return (
    <nav className='NavbarItems'>
      <Link className='home' to={'/'}>
        <div className='NavbarLogo'>
          <img src={Logo} alt='Progresa' />
        </div>
      </Link>

      <div className='menu-icon' onClick={handleClick}>
        <i className={clicked ? 'fa fa-times' : 'fa fa-bars'}></i>
      </div>

      <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
        {NavItems.filter((item) => item.hierarchy === 0).map((item, index) => {
          return (
            <li key={index}>
              <Link className={item.class} to={item.url}>
                <i className={item.icon} aria-hidden='true'></i>
                {item.title}
              </Link>

              <ul className={item.submenu ? 'submenu' : 'invisible'}>
                {NavItems.filter((SubItem) => SubItem.hierarchy === 1).map(
                  (subItem, index2) => {
                    return (
                      <li key={index2}>
                        <Link className={subItem.class} to={subItem.url}>
                          {subItem.title}
                        </Link>
                      </li>
                    );
                  }
                )}
              </ul>
            </li>
          );
        })}
        {currentUser ? (
          <li className='admin-section'>
            <button className='profile-btn'>
              <i className='fa fa-user' aria-hidden='true'></i>
            </button>

            <ul className='submenu'>
              <li>
                <Link className='nav-sublinks' to={'/perfil'}>
                  <i className='fa fa-cog' aria-hidden='true'></i>
                  Configuración
                </Link>
              </li>
              <li>
                <Link className='nav-sublinks' onClick={handleSubmit}>
                  <i className='fa fa-sign-out' aria-hidden='true'></i>Cerrar
                  sesión
                </Link>
              </li>
            </ul>
          </li>
        ) : (
          <></>
        )}
      </ul>
    </nav>
  );
}

export default Navbar;
