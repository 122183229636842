import '../Styles/blog.css';
import React, { Component } from 'react';

class Article extends Component {
  render() {
    return (
      <div className={this.props.clase}>
        <div className='des-text'>
          <h2>{this.props.title}</h2>
          <p>{this.props.text}</p>
        </div>

        <div className='des-img'>
          <img alt='img' src={this.props.imag1} />
          <img alt='img2' src={this.props.imag2} />
        </div>
      </div>
    );
  }
}

export default Article;
