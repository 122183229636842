import './App.css';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Home from './routes/Home';
import Nosotros from './routes/Nosotros';
import Productos from './routes/Productos';
import Noticias from './routes/Noticias';
import Reclamos from './routes/Reclamos';
import Contrataciones from './routes/Contrataciones';
import Contacto from './routes/Contacto';
import Canales from './routes/Canales';
import Registro from './routes/Registro';
import InicioSesion from './routes/InicioSesion';
import Perfil from './routes/Perfil';
import ErrorPage from './routes/ErrorPage';
import PrivateRoute from './components/PrivateRoute';
import SingleNews from './components/news/SingleNews';
import EditNews from './components/news/EditNews';
import AdminRoute from './components/AdminRoute';
import SingleHiring from './components/hiring/SingleHiring';
import EditHiring from './components/hiring/EditHiring';

function App() {
  return (
    <div className='App'>
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/nosotros' element={<Nosotros />} />
          <Route path='/contacto' element={<Contacto />} />
          <Route path='/noticias' element={<Noticias />} />
          <Route path='/noticias/:id' element={<SingleNews />} />
          <Route path='/reclamos' element={<Reclamos />} />
          <Route path='/contrataciones' element={<Contrataciones />} />
          <Route path='/contrataciones/:id' element={<SingleHiring />} />
          <Route path='/productos' element={<Productos />} />
          <Route path='/canales' element={<Canales />} />

          <Route element={<PrivateRoute />}>
            <Route path='/registro' element={<Registro />} />
            <Route path='/iniciarsesion' element={<InicioSesion />} />
          </Route>

          <Route element={<AdminRoute />}>
            <Route path='/perfil' element={<Perfil />} />
            <Route path='/noticias/editar/:id' element={<EditNews />} />
            <Route path='/contrataciones/editar/:id' element={<EditHiring />} />
          </Route>

          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;
