import React from 'react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import '../../Styles/news.css';

function DashboardNews() {
  const { news } = useContext(AuthContext);

  function formatDate(data) {
    let d = new Date(data);

    const date = [
      ('0' + d.getDate()).slice(-2),
      ('0' + (d.getMonth() + 1)).slice(-2),
      d.getFullYear(),
    ].join('-');

    return date;
  }

  return (
    <div className='DashboardN-container'>
      {news.length > 0 ? (
        <div className='header'>
          <h2>Noticias</h2>
          <span>Mantente siempre informado del acontecer</span>
        </div>
      ) : (
        ''
      )}

      {news.length > 0 ? (
        <div className='principal' key={news[0].id}>
          <div className='item-img'>
            <img
              src={`${process.env.REACT_APP_DOMAIN}/${news[0].image}`}
              alt=''
            />
          </div>

          <div className='item-text'>
            <div className='top-content'>
              <Link className='title' to={`/noticias/${news[0].id}`}>
                {news[0].title}
              </Link>
              <span className='date'>
                <i className='fa fa-calendar' aria-hidden='true'></i>
                {formatDate(news[0].date)}
              </span>
            </div>

            <p className='resumen'>{news[0].resume}</p>
          </div>
        </div>
      ) : (
        ''
      )}

      <div className='list'>
        {news.length > 1 &&
          news.map((item, i) => {
            if (i > 0 && i < 4) {
              return (
                <div className='content' key={item.id}>
                  <div className='item-img'>
                    <img
                      src={`${process.env.REACT_APP_DOMAIN}/${item.image}`}
                      alt=''
                    />
                  </div>

                  <div className='item-text'>
                    <Link className='title' to={`/noticias/${item.id}`}>
                      {item.title}
                    </Link>

                    <span className='date'>
                      <i className='fa fa-calendar' aria-hidden='true'></i>
                      {formatDate(item.date)}
                    </span>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}

        {news.length > 0 ? (
          <Link className='news-btn' to={`/noticias`}>
            Más noticias
            <span>
              <i className='fa fa-chevron-right' aria-hidden='true'></i>
              <i className='fa fa-chevron-right' aria-hidden='true'></i>
            </span>
          </Link>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default DashboardNews;
