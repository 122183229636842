import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../Styles/hiring.css';
import moment from 'moment';

const modules = {
  toolbar: [
    [{ header: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
    [{ color: [] }, { background: [] }],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    // [],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = [
  'header',
  'size',
  'color',
  'background',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
];

function CreateHiring() {
  const [title, setTitle] = useState('');
  const [resume, setResume] = useState('');
  const [content, setContent] = useState('');
  const [files, setFiles] = useState('');
  const [fileName, setFileName] = useState('Seleccionar Archivo');
  const navigate = useNavigate();

  function filebtn(e) {
    setFiles(e.files);
    if (typeof e.files != 'undefined') {
      if (e.files.length === 0) {
        setFileName('Seleccionar Archivo');
      } else {
        let file = e.files[0];
        let name = file.name;
        let size = (file.size / 1048576).toFixed(3);
        setFileName(name + ' (' + size + 'mb)');
      }
    } else {
      let name = e.value.split('\\');
      setFileName(name[name.length - 1]);
    }
    return false;
  }

  async function uploadImage() {
    try {
      const fileData = new FormData();
      fileData.append('file', files[0]);
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/upload`,
        fileData
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }

  async function createNewPost(ev) {
    ev.preventDefault();
    const imgUrl = await uploadImage();

    const data = new FormData();
    data.append('title', title);
    data.append('resume', resume);
    data.append('date', moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'));
    data.append('content', content);
    data.append('image', imgUrl);

    const response = await axios.post(
      `${process.env.REACT_APP_DOMAIN}/api/contrataciones`,
      data,
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    if (response.status === 200) {
      navigate(0);
    } else {
      await axios.post(`${process.env.REACT_APP_DOMAIN}/api/erase/`, {
        path: imgUrl,
      });
    }
  }

  return (
    <div className='createhiring-container'>
      <h2>Crear publicación</h2>
      <form onSubmit={createNewPost}>
        <input
          type='text'
          placeholder={'Titulo'}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <textarea
          type='text'
          placeholder={'Breve resumen de la publicación'}
          value={resume}
          onChange={(e) => setResume(e.target.value)}
          required
        />
        <label htmlFor='arch'>
          <span className='file-title'>
            <i className='fa fa-picture-o' aria-hidden='true'></i>
          </span>
          <span className='file-name'>{fileName}</span>
          <input
            id='arch'
            type='file'
            onChange={(e) => filebtn(e.target)}
            required
          />
        </label>

        <ReactQuill
          value={content}
          placeholder={'...'}
          onChange={(newValue) => setContent(newValue)}
          modules={modules}
          formats={formats}
          bounds={'.createhiring-container'}
          required
        />
        <button>Publicar</button>
      </form>
    </div>
  );
}

export default CreateHiring;
