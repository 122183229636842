import '../Styles/credits.css';
import React, { Component } from 'react';

class Credits extends Component {
  render() {
    return (
      <div className={this.props.clase}>
        <div className='item-text'>
          <h2 className='title'>{this.props.title}</h2>
          <p className='resumen'>{this.props.text}</p>
        </div>

        <div className='item-img'>
          <img alt='img' src={this.props.imag} />
        </div>
      </div>
    );
  }
}

export default Credits;
