import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/notfound.css';

function NotFound() {
  return (
    <div className='notfound'>
      <h1>404</h1>
      <h2>
        PAGINA NO ENCONTRADA{' '}
        <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>
      </h2>
      <p>Lo sentimos, parece que la pagina que buscas no existe.</p>
      <Link to='/'>
        <button>Volver al Inicio</button>
      </Link>
    </div>
  );
}

export default NotFound;
