import Footer from '../components/Footer';
import NotFound from '../components/NotFound';

function ErrorPage() {
  return (
    <>
      <NotFound />
      <Footer />
    </>
  );
}

export default ErrorPage;
