import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/carousel.css';

function CarouselItem(props) {
  const bg = { backgroundImage: `url(${props.bgImage})` };
  const colorButton = { backgroundColor: `${props.btnColor}` };
  const colorTitle = { color: `${props.colorTitle}` };
  const colorDescrip = { color: `${props.colorDescription}` };

  return (
    <div className={props.class} style={bg}>
      <div className='item-content'>
        <p className='text-lg' style={colorTitle}>
          {props.title}
        </p>
        <p className='text-sm' style={colorDescrip}>
          {props.description}
        </p>
        <div className='text' style={colorTitle}>
          <i className={props.icon1} aria-hidden='true'></i>
          {props.icon1 === 'fa fa-whatsapp' ? (
            <a
              href={
                'https://wa.me/51941479449?text="Buenas, deseo solcitar un credito."'
              }
            >
              {props.info1}
            </a>
          ) : (
            props.info1
          )}
        </div>
        <div className='text' style={colorTitle}>
          <i className={props.icon2} aria-hidden='true'></i>
          {props.info2}
        </div>
        {props.btnText ? (
          <Link className='btn' style={colorButton} to={props.btnLink}>
            <span>{props.btnText}</span>
          </Link>
        ) : (
          ''
        )}
      </div>

      <div className='item-img'>
        <img src={props.ProductImage} alt='itemImg' />
      </div>
    </div>
  );
}

export default CarouselItem;
