import React, { useState } from 'react';
import { DDListData } from './dataItems';
import '../Styles/dropDownList.css';

function DropDownList() {
  const [ind, setInd] = useState();

  const handleClick = (e, key) => {
    ind === key ? setInd() : setInd(key);
  };

  return (
    <div className='ddlist-container'>
      <h1>Cancela tus coutas en nuestros canales de pago</h1>
      <p>
        Elige el canal adecuado para realizar tus operaciones de manera facil y
        segura
      </p>

      <div className='ddl-container'>
        {DDListData.map((item, index) => {
          return (
            <div
              key={index}
              data-key={index}
              className='ddl-content'
              style={{ background: item.color }}
              onClick={(e) => handleClick(e, index)}
            >
              <div className='item-image'>
                <img src={item.image} alt='ddlItem' />
              </div>

              <div className={index === ind ? 'item-text act' : 'item-text'}>
                {item.description.map((art, idx) => {
                  return (
                    <div key={idx}>
                      <h3>
                        <i className='fa fa-check' aria-hidden='true'></i>
                        {art.title}
                      </h3>
                      <p>
                        <i
                          className='fa fa-angle-double-right'
                          aria-hidden='true'
                        ></i>
                        {art.info1}
                      </p>
                      <p>
                        <i
                          className='fa fa-angle-double-right'
                          aria-hidden='true'
                        ></i>
                        {art.info2}
                      </p>
                      <p>
                        <i
                          className='fa fa-angle-double-right'
                          aria-hidden='true'
                        ></i>
                        {art.info3}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DropDownList;
