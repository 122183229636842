import React from 'react';
import RegisterForm from '../components/RegisterForm';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Registro() {
  return (
    <>
      <Navbar />
      <div className='header-space'>
        <RegisterForm />
      </div>
      <Footer />
    </>
  );
}

export default Registro;
