import React from 'react';
import Carousel from '../components/Carousel';
import Partners from '../components/Partners';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import DashboardNews from '../components/news/DashboardNews';

function Home() {
  return (
    <div>
      <Navbar />
      <Carousel />
      <DashboardNews />
      <Partners />
      <Footer />
    </div>
  );
}

export default Home;
