import img1 from '../assets/Nosotros2.jpg';
import img2 from '../assets/Nosotros3.jpg';
import img3 from '../assets/Mision1.jpg';
import img4 from '../assets/Mision2.jpg';
import img5 from '../assets/Vision1.png';
import img6 from '../assets/Vision2.png';
import Article from './Article.js';
import '../Styles/blog.css';

const Blog = () => {
  return (
    <div className='Blog'>
      <p className='blog-presentacion'>
        Somos una sociedad civil sin fines de lucro, que promueve la inclusión
        financiera de la población vulnerable, asentada en las zonas urbano
        marginales de la ciudad de Lima-Perú. Surgimos como una iniciativa
        profesional, para promover el desarrollo de aquellos emprendimientos de
        subsistencia que no tienen acceso al sistema financiero tradicional y se
        ven obligados a recurrir a prestamistas informales, cuyas tasas de
        interés son muy elevadas y recurren a mecanismos coercitivos de
        cobranzas.
      </p>
      <div className='blog-img'>
        <img alt='img' src={img1} />
        <img alt='img' src={img2} />
      </div>
      <p className='blog-presentacion'>
        Nuestra labor consiste principalmente en financiar el capital de trabajo
        de dichos emprendimientos, complementado con un proceso de capacitación
        y asistencia técnica, los mismos que son gestionados principalmente por
        mujeres que se encuentran en situación de pobreza, de tal manera que
        puedan generar un historial crediticio y de esta forma acceder a las
        ofertas de las instituciones que forman parte del sistema financiero
        tradicional.
      </p>
      <Article
        title='Misión'
        text={`Impulsar el desarrollo de los microempresarios que no tienen acceso al sistema financiero tradicional, a través de productos financieros y asesoría para la gestión de sus negocios.`}
        clase='first-des'
        imag1={img3}
        imag2={img4}
      />

      <Article
        title='Visión'
        text={`Ser una organización referente de la inclusión financiera en el Perú, considerada como una opción de financiamiento oportuna, por la cercanía al cliente y la calidad de servicio que brindamos.`}
        clase='first-des-reverse'
        imag1={img5}
        imag2={img6}
      />
      <div className='blog-valores'>
        <h2>Valores</h2>
        <p>
          <i className='fa fa-angle-double-right' aria-hidden='true'></i>Somos
          honestos y respetuosos en nuestras acciones.
        </p>
        <p>
          <i className='fa fa-angle-double-right' aria-hidden='true'></i>
          Actuamos de manera eficiente y eficaz para el logro de nuestros
          objetivos.
        </p>
        <p>
          <i className='fa fa-angle-double-right' aria-hidden='true'></i>Somos
          oportunos, confiables y cálidos con nuestros clientes.
        </p>
        <p>
          <i className='fa fa-angle-double-right' aria-hidden='true'></i>
          Trabajamos en equipo, haciendo sinergia potencializando nuestras
          competencias individuales.
        </p>
        <p>
          <i className='fa fa-angle-double-right' aria-hidden='true'></i>Las
          personas son nuestro mejor activo y lo cuidamos.
        </p>
        <p>
          <i className='fa fa-angle-double-right' aria-hidden='true'></i>Estamos
          en constante aprendizaje y desarrollo.
        </p>
      </div>
    </div>
  );
};

export default Blog;
