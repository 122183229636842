import React from 'react';
import CreateNews from '../components/news/CreateNews';
import NewsFormat from '../components/news/NewsFormat';
import { useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Noticias() {
  const { currentUser, news } = useContext(AuthContext);

  return (
    <>
      <Navbar />
      <div className='header-space'>
        {currentUser ? <CreateNews /> : ''}

        {news.length > 0 &&
          news.map((post, index) => (
            <NewsFormat key={index} index={index} {...post} />
          ))}
      </div>
      <Footer />
    </>
  );
}

export default Noticias;
