import React from 'react';
import Hero from '../components/Hero';
import HeroImg from '../assets/hero2.jpg';
import DropDownList from '../components/DropDownList';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

function Canales() {
  return (
    <div>
      <Navbar />
      <Hero
        clase='hero-mid'
        img={HeroImg}
        title='Canales'
        textstyle='Hero-text-mid'
        btnstyle='hide'
      />
      <DropDownList />
      <Footer />
    </div>
  );
}

export default Canales;
