import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Navbar from '../Navbar';
import Footer from '../Footer';
import NotFound from '../NotFound';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  toolbar: [
    [{ header: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
    [{ color: [] }, { background: [] }],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    // [],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = [
  'header',
  'size',
  'color',
  'background',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'align',
];

function EditHiring() {
  const { id } = useParams();
  const [title, setTitle] = useState('');
  const [resume, setResume] = useState('');
  const [content, setContent] = useState('');
  const [url, setUrl] = useState('');
  const [files, setFiles] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [fileName, setFileName] = useState('Seleccionar Archivo');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/contrataciones/${id}`
        );
        setTitle(res.data.title);
        setResume(res.data.resume);
        setContent(res.data.content);
        setUrl(res.data.image);
      } catch {
        setIsValid(false);
      }
    };
    fetchData();
  }, [id]);

  function filebtn(e) {
    setFiles(e.files);
    if (typeof e.files != 'undefined') {
      if (e.files.length === 0) {
        setFileName('Seleccionar Archivo');
      } else {
        let file = e.files[0];
        let name = file.name;
        let size = (file.size / 1048576).toFixed(3);
        setFileName(name + ' (' + size + 'mb)');
      }
    } else {
      let name = e.value.split('\\');
      setFileName(name[name.length - 1]);
    }
    return false;
  }

  async function uploadImage() {
    if (files.length < 1) return '';
    try {
      const fileData = new FormData();
      fileData.append('file', files[0]);
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/upload`,
        fileData
      );
      return res.data;
    } catch (err) {
      console.log(err);
    }
  }

  async function updatePost(ev) {
    ev.preventDefault();

    const imgUrl = await uploadImage();
    if (imgUrl) {
      await axios.post(`${process.env.REACT_APP_DOMAIN}/api/erase/`, {
        path: url,
      });
    }

    const response = await axios.put(
      `${process.env.REACT_APP_DOMAIN}/api/contrataciones/${id}`,
      {
        title,
        resume,
        content,
        image: files ? imgUrl : url,
      },
      {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
        },
      }
    );
    if (response.status === 200) {
      navigate('/contrataciones');
    }
  }

  if (!isValid) return <NotFound />;
  return (
    <>
      <Navbar />
      <div className='header-space'>
        <div className='edithiring-container'>
          <h2 className='edit-title'>Editar publicación</h2>
          <form onSubmit={updatePost}>
            <input
              type='title'
              placeholder={'Titulo'}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />

            <input
              type='resume'
              placeholder={'Resumen'}
              value={resume}
              onChange={(e) => setResume(e.target.value)}
              required
            />
            <label htmlFor='arch'>
              <span className='file-title'>
                <i className='fa fa-picture-o' aria-hidden='true'></i>
              </span>
              <span className='file-name'>{fileName}</span>
              <input
                id='arch'
                type='file'
                onChange={(e) => filebtn(e.target)}
              />
            </label>
            <ReactQuill
              value={content}
              placeholder={'Escribe los detalles de tu publicación...'}
              onChange={(newValue) => setContent(newValue)}
              modules={modules}
              formats={formats}
              bounds={'.createhiring-container'}
            />
            <button>Actualizar</button>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EditHiring;
