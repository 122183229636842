import React from 'react';
import '../Styles/information.css';

function Information() {
  return (
    <div className='reclamos'>
      <h1>Libro de Reclamaciones</h1>

      <p>
        En este apartado podras acceder las vias para generar algun reclamo o
        queja que puedas tener, mediante los siguientes canales:
      </p>
      <br />

      <p>
        <i className='fa fa-angle-double-right' aria-hidden='true'></i>
        Oficinas de Atención al Público, ubicadas en:
      </p>
      <ul>
        <li>Chosica</li>
        <li>Carapongo</li>
        <li>Huaycan</li>
        <li>Huachipa</li>
      </ul>

      <p>
        <i className='fa fa-angle-double-right' aria-hidden='true'></i>Vía
        Telefónica: (01) 987 654 321
      </p>
      <p>
        <i className='fa fa-angle-double-right' aria-hidden='true'></i>Llenando
        el siguiente formulario.
      </p>
    </div>
  );
}

export default Information;
