import React from 'react';
import Hero from '../components/Hero';
import HeroImg from '../assets/hero2.jpg';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Cred1 from '../assets/credit1.jpg';
import Cred2 from '../assets/credit2.jpg';
import Cred3 from '../assets/credit3.jpg';
import Cred4 from '../assets/credit4.jpg';

import Credits from '../components/Credits';

function Productos() {
  return (
    <div>
      <Navbar />
      <Hero
        clase='hero-mid'
        img={HeroImg}
        title='Productos'
        textstyle='Hero-text-mid'
        btnstyle='hide'
      />

      <p className='credit-presentacion'>
        Todos nuestros créditos están destinados a personas naturales que
        desarrollan una actividad económica independiente, y han sido diseñados
        de acuerdo a las necesidades financieras de sus negocios.
      </p>

      <Credits
        title='CAPITAL DE TRABAJO'
        text={`Has crecer tus ingresos agenciándote de mercadería para tu negocio, a través de nuestro crédito capital de trabajo.`}
        clase='credit-container'
        imag={Cred1}
      />
      <Credits
        title='ACTIVOS FIJO O REFACCIONARIO'
        text={`Ahora podrás comprar o refaccionar los muebles, maquinarias y equipos de tu negocio, a través de nuestro crédito activo fijo o refaccionario.`}
        clase='credit-container inverse'
        imag={Cred2}
      />
      <Credits
        title='LIBRE DISPONIBILIDAD'
        text={`Podrás financiar tus programas de educación y capacitación continua, a través de nuestro crédito de libre disponibilidad.`}
        clase='credit-container'
        imag={Cred3}
      />
      <Credits
        title='CAMPAÑA O ESTACIONAL'
        text={`Necesitas realizar una inversión adicional en tu negocio o disponer de liquidez para campañas comerciales, solicita nuestro crédito campaña o estacional.`}
        clase='credit-container inverse'
        imag={Cred4}
      />
      <Footer />
    </div>
  );
}

export default Productos;
