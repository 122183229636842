import React from 'react';
import '../Styles/partners.css';
import { PartnersData } from './dataItems';

function Partners() {
  return (
    <div className='partner-container'>
      <h1>Nuestros grandes aliados</h1>
      <p>Principales organizaciones que apoyan nuestro trabajo</p>

      <div className='pt-container'>
        {PartnersData.map((item, index) => {
          return (
            <div key={index} className='pt-content'>
              <div className='item-image'>
                <a href={item.url}>
                  <img src={item.image} alt={item.name} />
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Partners;
