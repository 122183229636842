import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null
  );
  const [news, setNews] = useState([]);
  const [hirings, setHirings] = useState([]);

  async function signup(username, password, created) {
    try {
      const res = await axios.post(
        'https://progresa-rest-api-backend.onrender.com/api/auth/registro',
        {
          username,
          password,
          created,
        }
      );
      return res;
    } catch (err) {
      return err.response;
    }
  }

  async function login(username, password) {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_DOMAIN}/api/auth/iniciarsesion`,
        { username, password },
        {
          withCredentials: true,
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Credentials': true,
            'Access-Control-Allow-Origin': `${process.env.REACT_APP_CLIENT}`,
          },
        }
      );
      setCurrentUser(res.data);
      return res;
    } catch (err) {
      return err.response;
    }
  }

  async function logout() {
    await axios.post(`${process.env.REACT_APP_DOMAIN}/api/auth/salir`, {
      withCredentials: true,
      'Access-Control-Allow-Origin': `${process.env.REACT_APP_CLIENT}`,
    });
    setCurrentUser(null);
  }

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(currentUser));
  }, [currentUser]);

  useEffect(() => {
    const fetcData = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/noticias`
        );
        setNews(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetcData();
  }, []);

  useEffect(() => {
    const fetcHiring = async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_DOMAIN}/api/contrataciones`
        );
        setHirings(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetcHiring();
  }, []);

  function updateUser() {}
  function resetpassword() {}

  const value = {
    currentUser,
    signup,
    login,
    logout,
    resetpassword,
    updateUser,
    news,
    hirings,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
