import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Home from '../routes/Home';

function PrivateRoute() {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? <Home /> : <Outlet />;
}

export default PrivateRoute;
